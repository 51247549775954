
import { fetchBanners } from '../../utils/data';
import MainBanner from '../ui/MainBanner';
import SmallBanner from '../ui/SmallBanner';
import useFetch from '../../custom hooks/UseFetch';
import { Banner } from '../../types';
import React from 'react';
import Loading from '../ui/Loading';

const Hero = () => {
    const { data: banners, loading, error } = useFetch<Banner[]>(fetchBanners);
    if (loading) return <div className='fixed w-screen top-0 left-0 h-[100vh] bg-white z-50'><Loading/></div>;
    if (error) return <div>Error fetching banners: {error.message}</div>;
    return (
        <section className=' p-container main-padding overflow-x-hidden'>
            <ul className=' flex xs:flex-col lg:flex-row gap-10 '>
                {banners?.map((banner, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ?
                            <div  className='md:w-full lg:w-4/5 min-h-full bg-secondry'>
                                <MainBanner banner={banner} />
                            </div>
                            :
                            index === 1 ? 
                            <div   className='md:w-full lg:w-1/5 bg-darkColor'>
                                <SmallBanner banner={banner} />
                            </div>
                                : ""
                        }
                    </React.Fragment>
                ))
                }
            </ul>
        </section>
    )
}

export default Hero