import React from 'react'
import SectionHead from '../ui/SectionHead'
import { useTranslation } from 'react-i18next'
import useFetch from '../../custom hooks/UseFetch';
import { fetchHotDeals } from '../../utils/data';
import Product from './Product';
import Slider from '../ui/Slider';
import { ProductType } from '../../types';

const BestOffers = () => {
	const { t, } = useTranslation();
	const { data: hotDeals} = useFetch(fetchHotDeals);

	return (
		<div className=' p-container main-padding'>
			<SectionHead title={t("best-offers")} action={t("shop-now")} />
			<Slider
				items={hotDeals}
				renderSlide={(item:ProductType) => <Product product={item} />}
			/>
		</div>
	)
}

export default BestOffers