import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex-center flex-col  h-screen text-center ">
            <div className=' relative  overflow-hidden h-72'>
                <img src="/asset/not-found.png" className='w-full max-h-full' loading='lazy' alt="not-found" />
            </div>
            <p className="text-2xl text-darkColor mb-8">Page Not Found</p>
            <Link to="/" className="px-4 py-2 bg-primary text-white rounded-full hover:bg-secondary">
                Go Home
            </Link>
        </div>
    );
}

export default NotFound