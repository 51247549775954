import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import StarRating from '../ui/StarRating';
import { ProductType } from '../../types';

const Product = ({ product }: { product: ProductType }) => {
    const [hot, setHot] = useState(false);
    const [newest, setNewest] = useState(false);
    const now = new Date();
    const productDate = new Date(product.createdAt);
    const timeDifference = now.getTime() - productDate.getTime();
    const hoursDifference = timeDifference / (1000 * 3600);
    useEffect(() => {
        if (product.discount?.percentage !== undefined) {
            if (product?.discount?.percentage >= 15) {
                setHot(true);
            }
        }
        if (hoursDifference <= 48) {
            setNewest(true)
        }
    }, [product.discount?.percentage, hoursDifference]);

    return (
        <Link to={`/product-detail/${product.id}`} className=' relative'>
            <div className='h-72 flex-center'>
                <img className='h-full w-auto' src={product.images[0]} alt="product-img" loading='lazy' />
            </div>
            <div className='p-3 '>
                <h2 className='pb-1 text-lg font-semibold'>{product.name}</h2>
                <div className='pb-5 text-darkColor h-20  '>
                    <p className='' >{product.description}</p>
                </div>

                <div className='pt-1' ><StarRating rating={product.rating} /></div>
                <div className='flex-between pt-3'>
                    {product.discount?.discounted_price && <p>{product.discount.discounted_price} $</p>}
                    <p className='text-gray-400 line-through'>{product.price} $</p>
                </div>
            </div>
            <div className=' z-10 absolute top-2 right-2 flex gap-2'>
                {hot && <div className='p-2 rounded-full text-sm text-white animate-pulse bg-primary'>Hot</div>}
                {newest && <div className=' flex-center py-1 px-2 rounded-md text-sm text-white bg-darkColor'>New</div>}
            </div>
        </Link>
    )
}

export default Product