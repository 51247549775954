
import { GlobalStateContext, useStateContext } from '../../context/StateContext'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
const CartItem = ({ product }: any) => {
    const { t } = useTranslation();
    const { toggleCartItemQuanitity, onRemove } = useStateContext() as GlobalStateContext;
    return (
        <div className=' grid md:grid-cols-4 xs:gap-5 md:gap-10 xs:grid-cols-1 items-center py-5'>
            <div className='flex items-center'>
                    <div className=' w-44 '>
                        <img className='h-auto w-full' src={product?.images[0]} alt="cart-item" />
                    </div>

                <div className='flex flex-col gap-2'>
                    <p className=' font-bold text-darkColor'>{product?.name}</p>
                    <p >{product?.discount?.discounted_price ? product?.discount?.discounted_price : product?.price}$</p>
                </div>
            </div>
            <div className='flex-between'>
            <p className='xs:flex md:hidden'>{t("price")}:</p>
            <p className=' text-primary font-semibold'>{product?.discount?.discounted_price ? product?.discount?.discounted_price : product?.price}$</p>
            </div>
            <div className='flex-between'>
            <p className='xs:flex md:hidden'>{t("quantity")}:</p>
                <p className="flex items-center w-fit gap-2 border-[1px] border-darkColor">
                <span className="p-2 cursor-pointer border-e-[1px] text-red-500 border-darkColor" onClick={() => toggleCartItemQuanitity(product.id, 'dec')}>
                    <AiOutlineMinus />
                </span>
                <span className="px-2" >{product?.quantity}</span>
                <span className="p-2 cursor-pointer border-s-[1px] text-green-500 border-darkColor" onClick={() => toggleCartItemQuanitity(product.id, 'inc')}><AiOutlinePlus /></span>
            </p>
            </div>
            
            <button
                type='button'
                className='text-white bg-primary px-4 py-2 rounded-3xl font-semibold w-fit'
                onClick={() => onRemove(product)}>{t("remove")}</button>
        </div>
    )
}

export default CartItem