import { useLocation } from "react-router-dom";
import Ad from "../../components/Home/Ad";
import BestOffers from "../../components/Home/BestOffers";
import Categorey from "../../components/Home/Categorey";
import ElectronicOffers from "../../components/Home/ElectronicOffers";
import Hero from "../../components/Home/Hero";
import NewOffers from "../../components/Home/NewOffers";
import { useEffect } from "react";
import { useStateContext, GlobalStateContext } from "../../context/StateContext";
import { fetchUser } from "../../utils/data";
const Home = () => {
  const { hash } = useLocation();
  const { setUser } = useStateContext() as GlobalStateContext;
  useEffect(() => {
    const getUserData = async () => {
        try {
            const userData = await fetchUser();
            setUser(userData);
        } catch (error) {
            console.error('Error fetching user data', error);
        }
    };
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  return (
    <div>
      <Hero />
      <BestOffers />
      <NewOffers />
      <Ad />
      <ElectronicOffers />
      <Categorey />
    </div>
  )
}

export default Home