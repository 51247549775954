import { navbarlinks, navbarlinks_en } from '../../constant';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react'
import { IoMdClose, IoMdLogIn, IoMdPersonAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import Logo from '../ui/Logo';
import { MobileNavProps } from '../../types';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import Cookies from 'js-cookie';
import { CgLogOut } from "react-icons/cg";

const MobileNavbar: React.FC<MobileNavProps> = ({ open, setOpen }) => {
    const { user } = useStateContext() as GlobalStateContext;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <div className={`fixed bg-[#00000090] z-50 md:hidden xs:flex  h-full top-0 left-0 ${open ? "w-full" : "w-0"}`}>
            <div className={`fixed bg-secondry z-50 transition-all duration-300  h-full top-0 left-0 py-8 ${open ? "w-4/6 overflow-visible" : "w-0 overflow-hidden"} `}>
                <button type='button' onClick={() => setOpen(false)} title='close menu' className={` ${open ? "flex" : "hidden"} text-white absolute -right-12 z-50 border-[1px] border-secondry p-1 text-xl`}>
                    <IoMdClose />
                </button>
                <div className='flex justify-between flex-col h-full'>
                    <div>
                        <div className='px-6 border-b-darkColor border-b-[1px] pb-4'>
                            <Logo />
                        </div>
                        <ul className=' flex flex-col py-3 px-6 gap-5 '>
                            {links.map((link, index) => (
                                <li key={index} className=' text-darkColor font-semibold transition-all hover:scale-105'>
                                    <Link to={link.href} className='w-full text-start'>
                                        <button className="w-full text-start" type="button" onClick={() => setOpen(false)}>
                                            {link.name}
                                        </button>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {!user ?
                        <div className='flex flex-col gap-3 px-6'>
                            <button
                                type='button'
                                onClick={() => {
                                    setOpen(false)
                                    navigate("/logIn")
                                }}
                            className='bg-primary text-white transition-all hover:scale-105 px-3 py-2 flex-center gap-1'>
                                <IoMdLogIn />
                                <p>{t("login")}</p>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    setOpen(false)
                                    navigate("/signUp")
                                }}
                                className=' border-2 text-primary border-primary  transition-all hover:scale-105 px-3 py-2 flex-center gap-1'>
                                <IoMdPersonAdd />
                                <p>{t("signup")}</p>
                            </button>
                        </div> :
                        <div className=' p-6 flex-between'>
                            <button
                                type='button'
                                onClick={() => {
                                    Cookies.remove('accessToken');
                                    navigate("/")
                                }}
                                className='flex items-center  text-red-600 xs:px-2 sm:px-4 py-2 rounded-md font-semibold xs:gap-1 sm:gap-2'>
                                {t("logout")} <CgLogOut />
                            </button>
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default MobileNavbar
