import { useTranslation } from 'react-i18next';
import { categories, categories_en } from '../../constant';
import SectionHead from '../ui/SectionHead';
import CategoryItem from '../ui/CategoryItem';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
const Categorey = () => {
    const { t,i18n } = useTranslation();
    const Category = i18n.language === "ar" ? categories : categories_en;
	return (
		<div className=' p-container main-padding'>
			<SectionHead title={t("shop-with-category")} action={t("shop-now")} />
			<div className=' py-7'>
			<Swiper
                spaceBetween={20}
                slidesPerView={3}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    1300: {
                        slidesPerView: 5,
                    },

                }}
                dir={i18n.language==="ar"?'rtl':"ltr"}
            >
                {Category.map((item, index) => (
                    <SwiperSlide key={index}>
						<CategoryItem item={item}/>
                    </SwiperSlide>
                ))}
            </Swiper>
			</div>
			
		</div>
	)
}

export default Categorey