import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { SignUpData } from '../../types';
import toast from 'react-hot-toast';
import Input from '../ui/Input';
import { handleAuth } from '../../utils/data';
const SignUpForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [signUpData, setSignUpData] = useState<SignUpData>({ name: '', email: '', phoneNumber: '', password: '' });
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (confirmPassword !== signUpData.password) {
            toast.error("Plaese enter the same password in each filed")
        }
        else {
            handleAuth('/signUp', signUpData, 'Login successful', navigate);
        }
    };
    return (
        <div className=' p-container xs:w-4/5 md:w-2/5 flex flex-col justify-center '>
            <p className='sm:text-2xl xs:text-xl  text-black'>{t("hello")}</p>
            <p className='sm:text-3xl xs:text-2xl font-bold text-black'>{t("welcome")}</p>
            <form onSubmit={handleSignUp} className='main-padding flex flex-col gap-3'>
                <Input required title={t("name")} type="text" property={signUpData.name} changedProp={"name"} data={signUpData} setData={setSignUpData} />
                <Input required title={t("email")} type="email" property={signUpData.email} changedProp={"email"} data={signUpData} setData={setSignUpData} />
                <Input required title={t("phone-number")} type="number" property={signUpData.phoneNumber} changedProp={"phoneNumber"} data={signUpData} setData={setSignUpData} />
                <Input required title={t("password")} type="password" property={signUpData.password} changedProp={"password"} data={signUpData} setData={setSignUpData} />
                <div className="flex-col flex gap-2">
                    <label className='text-lg font-semibold text-[#191919]'>
                        {t("confirm-password")}
                        <span className='text-primary'>*</span>
                    </label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className='text-black  p-3 rounded-3xl bg-white focus:outline-none' />
                </div>
                <input type="submit"
                    value={t("sign-up")}
                    className=" cursor-pointer w-full mt-5  rounded-3xl py-3 bg-primary text-white transition-all hover:bg-secondry hover:border-primary hover:text-primary border-2 "
                />
                <Link to="/login" className="p-3 text-primary">{t("have-account")}</Link>
            </form>
        </div>
    )
}

export default SignUpForm
