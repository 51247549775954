import VerficationForm from '../../components/forms/VerficationForm'

const Verfication = () => {
  return (
    <div className='flex-center overflow-hidden'>
      <VerficationForm />
    </div>
  )
}

export default Verfication