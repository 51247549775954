import SignUpForm from "../../components/forms/SignUpForm"
const SignUp = () => {
    return (
        <div className='flex-center bg-secondry main-padding overflow-hidden'>
                <SignUpForm />
        </div>
    )
}

export default SignUp
