import React from 'react'
import ContactUsForm from '../../components/forms/ContactUsForm'
import SectionHead from '../../components/ui/SectionHead'
import { useTranslation } from 'react-i18next'
import Map from './Map'

const ContactUs = () => {
  const {t}=useTranslation();
  return (
    <div>
      <div className='p-container main-padding bg-gray-200'>
        <div className=' xs:w-full md:w-1/2'>
          <SectionHead title={t('contact-us')}/>
        <p className='pt-2 pb-8 text-gray-400 text-lg '>{t("contact-desc")}</p>
        </div>
        <ContactUsForm/>
      </div>
      <Map/>
      
    </div>
  )
}

export default ContactUs