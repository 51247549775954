
interface SectionHeadProps{
    title:string,
    action?:string
}
const SectionHead = ({title,action}:SectionHeadProps) => {
  return (
    <div className=' border-b-[1px] border-b-secondry flex-between'>
        <p className=' w-fit font-semibold xs:text-xl sm:text-2xl pe-10 py-2 border-b-2 border-b-darkColor'>{title}</p>
        
    </div>
  )
}

export default SectionHead