
import { useParams } from 'react-router-dom'
import useFetch from '../../custom hooks/UseFetch';
import { fetchProduct} from '../../utils/data';
import { useTranslation } from 'react-i18next';
import ImageSlider from '../../components/productDetails/ImageSlider';
import ProductDetailsCont from '../../components/productDetails/ProductDetailsCont';
import SectionHead from '../../components/ui/SectionHead';
import Slider from '../../components/ui/Slider';
import Product from '../../components/Home/Product';
import Loading from '../../components/ui/Loading';
import { ProductType } from '../../types';
const ProductDetails = () => {
    const { productId } = useParams();
    const { t } = useTranslation();
    const queryId=`id=${productId}`
    const { data: productArray, loading } = useFetch(fetchProduct, queryId);
    let product;
    if (productArray) {
        product = productArray[0];
    }

    const query = `category=${product?.category}`
    const { data: sameCategory } = useFetch(fetchProduct, query);
    if (loading) return <Loading/>;
    return (
        <div className='p-container main-padding'>
            <div className=' flex xs:flex-col md:flex-row xs:gap-10 md:gap-0 justify-center '>
                <div className='xl:w-1/3 md:w-1/2 xs:w-full'>
                    <ImageSlider images={product?.images} />
                </div>
                <div className='xl:w-2/3 md:w-1/2 xs:w-full'>
                    <ProductDetailsCont product={product} />
                </div>
            </div>
            {
                sameCategory &&
                <div className=' pt-10'>
                    <SectionHead title={t('also-like')} />
                    <Slider
                        items={sameCategory}
                        renderSlide={(item:ProductType) => <Product product={item} />}
                    />
                </div>
            }

        </div>
    )
}

export default ProductDetails