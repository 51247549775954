import React from 'react'
import SellerSidebar from './SellerSidebar'
import { useParams } from 'react-router-dom'
import useFetch from '../../custom hooks/UseFetch';
import { fetchSeller } from '../../utils/data';
import SellerProducts from './SellerProducts';
import Loading from '../../components/ui/Loading';
import { User } from '../../types';

const SellerProfile = () => {
  const { sellerId } = useParams();
  const {data:sellerArray,loading}=useFetch(fetchSeller,sellerId);
  if(loading) return <Loading/>
  const seller:User=sellerArray[0]
  return (
    <div className=' p-container main-padding flex xs:flex-col md:flex-row gap-5 bg-gray-200'>
      <div className='lg:w-1/4 md:w-2/5 '>
        <SellerSidebar  user={seller}/>
      </div>
      <div className='lg:w-3/4 md:w-3/5'>
        <SellerProducts user={seller} />
      </div>
    </div>
  )
}

export default SellerProfile