import React, { useState } from 'react'

const ImageSlider = ({ images }: { images :string[]}) => {
    const [index, setIndex] = useState(0);
    return (
        <div className=' sm:w-96 xs:w-72'>
            <div className=" sm:h-96 xs:h-72 sm:w-96 xs:w-72  mb-5 bg-secondry p-5 rounded-lg hover:bg-primary duration-300 transition-all">
                <img src={images[index]} alt='slider' className="w-full h-full" />
            </div>
            <div className=" gap-5 flex ">
                {images?.map((image: string, i: number) => (
                    <div key={i} className='sm:w-20 sm:h-20 xs:w-16 xs:h-16 bg-secondry xs:p-2 sm:p-3 rounded-lg'>
                        <img
                            key={i}
                            src={image}
                            alt='slider'
                            className={'w-full h-full cursor-pointer'}
                            onMouseEnter={() => setIndex(i)}
                        />
                        </div>
                ))}
            </div>
        </div>
    )
}

export default ImageSlider