import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import "./i18n"
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { StateContext } from './context/StateContext';
import Shop from './routes/shop/Shop';
import ProductDetails from './routes/productDetails/ProductDetails';
import SellerProfile from './routes/seller/SellerProfile';
import Home from './routes/home/Home';
import SignUp from './routes/signup/SignUp';
import Login from './routes/login/Login';
import ForgetPassword from './routes/forgetPassword/ForgetPassword';
import ForgetPasswordLayout from './routes/forgetPassword/ForgetPasswordLayout';
import ResetPassword from './routes/forgetPassword/ResetPassword';
import Verfication from './routes/forgetPassword/Verfication';
import ContactUs from './routes/contact-us/ContactUs';
import NotFound from './routes/NotFound';
import Cart from './routes/cart/Cart';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            { path: "logIn", element: <Login /> },
            { path: "signUp", element: <SignUp /> },
            { path: "shop", element: <Shop /> },
            { path: "shop/:query", element: <Shop /> },
            { path: "product-detail/:productId", element: <ProductDetails /> },
            { path: "sellerprofile/:sellerId", element: <SellerProfile /> },
            { path: "contact-us", element: <ContactUs /> },
            { path: "cart", element: <Cart /> },
            {
                path: "forget-passward",
                element: <ForgetPasswordLayout />,
                children: [
                    { index: true, element: <ForgetPassword /> },
                    { path: "reset-password", element: <ResetPassword /> },
                    { path: "verify-number", element: <Verfication /> },
                ]
            },
            { path: "*", element: <NotFound /> },
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <StateContext>
            <Toaster />
            <RouterProvider router={router} />
        </StateContext>
    </React.StrictMode>
);
