import { SignUpData } from './../types/index';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { MassageData, LoginData } from '../types';
import { jwtDecode } from 'jwt-decode';
const API_URL = 'https://souq-backend-production.up.railway.app';
 
const fetchData = async (url: string) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;  // Optionally re-throw the error if you want to handle it elsewhere
    }
};
// Fetch banners
export const fetchBanners = () => fetchData(`${API_URL}/banners`);

// Fetch hot deals
export const fetchHotDeals = () => fetchData(`${API_URL}/products?discount.percentage_gte=10`);

// Fetch new offers
export const fetchNewOffers = () => fetchData(`${API_URL}/products?_sort=id&_order=desc&_limit=8`);

// Fetch electronics
export const fetchElctronic = () => fetchData(`${API_URL}/products?category=Electronics`);

// Fetch ad
export const fetchAd = () => fetchData(`${API_URL}/ad`);

// Fetch all products
export const fetchProducts = () => fetchData(`${API_URL}/products`);

// Fetch products by category
export const fetchProduct = (query: any) => fetchData(`${API_URL}/products?${query}`);

// Fetch seller by ID
export const fetchSeller = (sellrId: any) => fetchData(`${API_URL}/users?id=${sellrId}`);

// handle auth
 export const handleAuth = async (url: string, requestData: LoginData | SignUpData, successMessage: string, navigate: any) => {
    try {
        const response = await fetch(`${API_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (!response.ok) {
            toast.error(`${data.message || 'An error occurred'}`);
            return null;
        }

        toast.success(successMessage);
        Cookies.set('accessToken', data.accessToken, { expires: 7 });
        navigate("/");
        return data;
    } catch (error) {
        console.error(`${error}: Failed to process ${url}`);
        return null;
    }
};


// fetch user 
export const fetchUser = async () => {
    const token = Cookies.get('accessToken');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.sub;
            const response = await fetch(`${API_URL}/users/${userId}`);
            const userData = await response.json();
            return userData;
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }
};

export const sendMsg = async (massageData:MassageData) => {
    try {
        const response = await fetch(`${API_URL}/massges`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(massageData),
        });

        if (!response.ok) {
            toast.error(`Faild to send Message`);
            return null;
        }

        toast.success("Message has been sent successfully");
    } catch (error) {
        return null;
    }
};
export const makeOrder = async (order:any) => {
    try {
        const response = await fetch(`${API_URL}/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        });

        if (!response.ok) {
            toast.error(`Done`);
            return null;
        }

        toast.success("order Updated Successfully");
    } catch (error) {
        return null;
    }
};


