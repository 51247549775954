
import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoStarOutline } from "react-icons/io5";
import { MdCrisisAlert, MdOutlineSell } from 'react-icons/md';
import useFetch from '../../custom hooks/UseFetch';
import Product from '../../components/Home/Product';
import { fetchProduct } from '../../utils/data';
import { User } from '../../types';
const SellerProducts = ({ user }: { user:User }) => {
    const { t } = useTranslation();
    const query=`seller.id=${user.id}`
    const {data:products}=useFetch(fetchProduct,query)

    return (
        <div className='w-full bg-white p-5 rounded-lg '>
            <div>
                <ul className='grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 border-b-[1px] pb-3'>
                    {
                        user.badges?.map((item: string, index: number) => (
                            <li key={index} className='flex items-center gap-2'><span className=' text-yellow-500 text-lg font-extrabold'><IoStarOutline /></span>  {item}</li>
                        ))
                    }
                </ul>
                <div className='flex xs:flex-col sm:flex-row sm:gap-10 xs:gap-3 py-5 w-full'>
                    <div className=' w-full p-5 rounded-lg bg-secondry'>
                        <p className='flex  items-center text-lg gap-2'>
                            <MdOutlineSell className='text-xl text-darkColor' />
                            {t("totalProductsSold")}
                        </p>
                        <p className=' text-4xl font-extrabold pt-3'>{user.totalProductsSold}</p>
                    </div>
                    <div className=' w-full p-5 rounded-lg bg-secondry'>
                        <p className='flex  items-center text-lg gap-2'>
                            <MdCrisisAlert className='text-xl text-darkColor' />
                            {t("activeProducts")}
                        </p>
                        <p className=' text-4xl font-extrabold pt-3'>{user.activeListings}</p>
                    </div>
                </div>
                <div className=' grid lg:grid-cols-3 md:grid-cols-2  gap-10 py-10'>
                    {products?.map((product:any,index:number)=>(
                        <div key={index}> <Product product={product}/></div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SellerProducts