import ForgetPasswordForm from "../../components/forms/ForgetPasswordForm"


const ForgetPassword = () => {
  return (
    <div className='flex-center  overflow-hidden'>
      <ForgetPasswordForm />
    </div>
  )
}

export default ForgetPassword