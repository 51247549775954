import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { searchChoose } from "../../constant";
import { useState } from "react";

const SearchForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [searchType, setSearchType] = useState("");
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        if (searchType && query) {
            const finalQuery = `${searchType.toLowerCase()}=${query.trim()}`
            navigate(`/shop/${finalQuery}`)
        }
    };
    return (
        <div>
            <form
                onSubmit={handleSearch}
                className='rounded-md overflow-hidden flex-center bg-secondry gap-4 w-full'>
                <select
                    className="bg-secondry ps-2 focus:outline-none"
                    value={searchType}
                    required
                    onChange={(e) => setSearchType(e.target.value)}
                >
                    <option value="" disabled>
                        Choose
                    </option>
                    {searchChoose.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.title}
                        </option>
                    ))}
                </select>
                <input
                    className='focus: ps-2 bg-inherit w-full'
                    type="text"
                    placeholder={t("search-placeholder")}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <button className='bg-darkColor text-white p-3' type="submit">
                    <FaSearch />
                </button>
            </form>
        </div>
    )
}

export default SearchForm
