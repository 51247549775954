
import { useTranslation } from 'react-i18next';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { paymentsWay } from '../../constant';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeOrder } from '../../utils/data';

const CartSidebar = () => {
    const { t } = useTranslation();
    const navigate=useNavigate();
    const { totalPrice,setTotalPrice,setTotalQuantities,setCartItems, user,cartItems } = useStateContext() as GlobalStateContext;
    const [selectedPayment, setSelectedPayment] = useState<string | null>(null);

    let delivery = 5;
    const clearData=()=>{
        setTotalPrice(0)
        setTotalQuantities(0)
        setCartItems([])
        localStorage.removeItem("cartItems");
        localStorage.removeItem("totalPrice");
        localStorage.removeItem("totalQuantities");
    }
    const handleCheckOut=()=>{
        if (!selectedPayment) {
            toast.error("Please select payment method");
            return;
        }
        if(!user){
            toast.error("please Login First")
            navigate("/login")
        }
        if(user&&selectedPayment){
            const order={
                cartItems:cartItems,
                totalPrice:totalPrice,
                selectedPayment:selectedPayment,
                clientId:user.id,
                clientName:user.name
            }
            try{
                makeOrder(order)
            }catch{
                toast.error("Failed to make order")
            }
            clearData();
                
        }
    }
    const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPayment(e.target.value);
    }
    return (
        <div >
            <p className=' text-xl font-semibold'>{t("total-cart")}</p>
            <div className=' flex-between pt-2'>
                <p>{t("subtotal")}</p>
                <p>{totalPrice}$</p>
            </div>
            <div className=' flex-between pb-2 border-b-[1px] border-secondry '>
                <p>{t("delivery")}</p>
                <p>{delivery}$</p>
            </div>
            <div className=' flex-between pt-2 pb-5'>
                <p>{t("total")}</p>
                <p>{totalPrice + delivery}$</p>
            </div>
            {user &&
                <div>
                    <div className=' flex-between'>
                        <p>{t("name")}</p>
                        <p>{user.name}</p>
                    </div>
                    <div className=' flex-between'>
                        <p>{t("address")}</p>
                        <p>{user.location}</p>
                    </div>
                </div>
            }

            <div className=' flex flex-col w-full gap-2 pb-5 pt-2 border-t-[1px] border-secondry'>
                {paymentsWay.map((item: string, index: number) => (
                    <div key={index} className='flex gap-1'>
                        <input required type="radio" name="color" value={item} id={item} onChange={handlePaymentChange}  />
                        <label htmlFor={item}>{item}</label>
                    </div>
                ))}
            </div>
            <button
                type="button"
                onClick={handleCheckOut}
                className=' text-lg text-white font-semibold bg-darkColor rounded-lg w-full py-2'>
                {t("place-order")}
            </button>
        </div>
    )
}

export default CartSidebar