import LoginForm from "../../components/forms/LoginForm"

const Login = () => {
    return (
        <div className=' bg-secondry  main-padding flex-center overflow-hidden'>
            <LoginForm />
        </div>
    )
}

export default Login
