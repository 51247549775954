import React from 'react'
import ShopSidebar from '../../components/shop/ShopSidebar'
import ShopContent from '../../components/shop/ShopContent'

const Shop = () => {
    
    return (
        <div className=' p-container main-padding bg-gray-200'>
            <div className=' flex xs:flex-col sm:flex-row  gap-10'>
                <div className=' xs:w-full sm:w-1/4'>
                    <ShopSidebar />
                </div>
                <div className=' xs:w-full sm:w-3/4'>
                    <ShopContent />
                </div>
            </div>
        </div>
    )
}

export default Shop