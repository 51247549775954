import React, { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ProductType, User } from "../types";

export interface GlobalStateContext {
    user: User | undefined;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    products: ProductType[] | undefined;
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
    cartItems: ProductType[];
    totalPrice: number;
    totalQuantities: number;
    qty: number;
    incQty: () => void;
    decQty: () => void;
    onAdd: (product: ProductType, quantity: number) => void;
    toggleCartItemQuanitity: (id: number, value: string) => void;
    onRemove: (product: ProductType) => void;
    setCartItems: React.Dispatch<React.SetStateAction<ProductType[]>>;
    setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
    setTotalQuantities: React.Dispatch<React.SetStateAction<number>>;
}
const Context = createContext<GlobalStateContext | null>(null);
interface StateContextProps {
    children: React.ReactNode;
}
export const StateContext = ({ children }: Readonly<StateContextProps>) => {
    const [user, setUser] = useState<User>();
    const [products, setProducts] = useState<ProductType[]>([]);
    const [cartItems, setCartItems] = useState<ProductType[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalQuantities, setTotalQuantities] = useState<number>(0);
    const [qty, setQty] = useState<number>(1);
    let foundProduct: any;
    const clearLocalStorage=()=>{
        localStorage.removeItem("cartItems");
        localStorage.removeItem("totalPrice");
        localStorage.removeItem("totalQuantities");
    }
    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        localStorage.setItem("totalPrice", JSON.stringify(totalPrice));
        localStorage.setItem("totalQuantities", JSON.stringify(totalQuantities));
    }, [cartItems, totalPrice, totalQuantities,setCartItems]);
    const onAdd = (product: any, quantity: number) => {
        const checkProductInCart = cartItems.find((item: any) => item.id === product.id);
        setTotalPrice((prevTotalPrice) => 
            prevTotalPrice + (product.discount?.discounted_price ? product.discount?.discounted_price : product.price) * quantity
        );
        setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);
    
        if (checkProductInCart) {
            const updatedCartItems = cartItems.map((cartProduct: any) => {
                if (cartProduct.id === product.id) {
                    return {
                        ...cartProduct,
                        quantity: cartProduct.quantity + quantity
                    };
                }
                return cartProduct;
            });
            setCartItems(updatedCartItems);
        } else {
            product.quantity = quantity;
            setCartItems([...cartItems, { ...product }]);
        }
    
        toast.success(`${qty} ${product.name} added to the cart.`);
    };
    
    const onRemove = (product: any) => {
        foundProduct = cartItems.find((item: any) => item.id === product.id);
        const newCartItems = cartItems.filter((item: any) => item.id !== product.id);
        setTotalPrice((prevTotalPrice) => prevTotalPrice - (foundProduct.discount?.discounted_price ? foundProduct.discount?.discounted_price:foundProduct.price) * foundProduct.quantity);
        setTotalQuantities(prevTotalQuantities => prevTotalQuantities - foundProduct.quantity);
        setCartItems(newCartItems);
        clearLocalStorage()
    }
    const toggleCartItemQuanitity = (id: number, value: string) => {
        const updatedCartItems = cartItems.map((item: any) => {
            if (item.id === id) {
                if (value === 'inc') {
                    return { ...item, quantity: item.quantity + 1 };
                } else if (value === 'dec') {
                    // Decrease quantity only if it's greater than 1
                    if (item.quantity > 1) {
                        return { ...item, quantity: item.quantity - 1 };
                    }
                }
            }
            return item;
        });
        const foundProduct = cartItems.find((item: any) => item.id === id);
        if (foundProduct) {
            if (value === 'inc') {
                setTotalPrice(prevTotalPrice => prevTotalPrice + (foundProduct.discount?.discounted_price ? foundProduct.discount?.discounted_price : foundProduct.price));
                setTotalQuantities(prevTotalQuantities => prevTotalQuantities + 1);
            } else if (value === 'dec'&& foundProduct.quantity !== undefined && foundProduct.quantity > 1) {
                setTotalPrice(prevTotalPrice => prevTotalPrice - (foundProduct.discount?.discounted_price ? foundProduct.discount?.discounted_price : foundProduct.price));
                setTotalQuantities(prevTotalQuantities => prevTotalQuantities - 1);
            }
            setCartItems(updatedCartItems);
        }
    };
    const incQty = () => {
        setQty((prevQty) => prevQty + 1);
    }

    const decQty = () => {
        setQty((prevQty) => {
            if (prevQty - 1 < 1) return 1;
            return prevQty - 1;
        });
    }
    return (
        <Context.Provider value={{
            user,
            setUser,
            products,
            setProducts,
            cartItems,
            totalPrice,
            totalQuantities,
            qty,
            incQty,
            decQty,
            onAdd,
            toggleCartItemQuanitity,
            onRemove,
            setCartItems,
            setTotalPrice,
            setTotalQuantities
        }}>
            {children}
        </Context.Provider>
    );
}
export const useStateContext = () => useContext(Context);