import React from 'react'
import { Link } from 'react-router-dom'

const CategoryItem = ({item}:any) => {
  return (
    <Link to={`/shop/category=${item.name}`} className=' p-2 flex-center'>
        <div className=' flex-col flex-center gap-5 '>
          <div className=' w-20 h-20 rounded-full overflow-hidden'>
          <img src={item.img} className=' w-full h-full' alt="category" loading='lazy' />
          </div>
            
            <p className=' text-darkColor text-lg  font-semibold'>{item.name}</p>
        </div>
    </Link>
  )
}

export default CategoryItem