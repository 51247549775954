import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { categories, categories_en, colors } from '../../constant';
import SectionHead from '../ui/SectionHead';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import useFetch from '../../custom hooks/UseFetch';
import {  fetchProduct, fetchProducts } from '../../utils/data';
import { CiCircleList } from "react-icons/ci";
import MobileFilter from './MobileFilter';
import { ProductType } from '../../types';
const ShopSidebar = () => {
    const [selectedColor, setSelectedColor] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<any>("");
    const [selectedBrands, setSelectedBrands] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [fetchAll, setFetchAll] = useState(false);
    const [brands, setBrands] = useState<string[]>([]);
    const { setProducts } = useStateContext() as GlobalStateContext;
    const { t, i18n } = useTranslation();

    const Categories = i18n.language === "ar" ? categories : categories_en;
    const fetchFunction = fetchAll ? fetchProducts : selectedCategory ? fetchProduct : fetchProducts;


    // get brands and remove duplicated brands
    const extractUniqueBrands = (data: any) => {
        const brands = data?.map((product: ProductType) => product.brand);
        const uniqueArray: any[] = [];
        brands.forEach((value: string) => {
            if (!uniqueArray.includes(value)) {
                uniqueArray.push(value);
            }
        });
        return uniqueArray;
    };

    let queryParams = '';

    if (selectedCategory) {
        queryParams = `category=${selectedCategory}&`;
    }
    const { data } = useFetch(fetchFunction, queryParams);


    useEffect(() => {
        if (data) {
            const brands: string[] = extractUniqueBrands(data);
            setBrands(brands);
            setProducts(data);
        }
    }, [data, setProducts]);

    const handleCategoryClick = (categoryName: string) => {
        setSelectedCategory(categoryName);
        setFetchAll(false);
    };
    const handleBrandClick = (brand: string) => {
        setSelectedBrands([...selectedBrands, brand]);
    };

    const handleAllProductsClick = () => {
        setSelectedCategory(null);
        setFetchAll(true);
    };
    const handleSubmitBrand = async (e: React.FormEvent) => {
        e.preventDefault();
        setSelectedCategory(null);
        let queryParams = "";
        if (selectedColor) {
            queryParams += `color=${selectedColor}&`;
        }
        if (selectedBrands.length > 0) {
            queryParams += `brand=${selectedBrands.join("&brand=")}`;
        }
        setFetchAll(false)
        try{
            const data= await fetchProduct(queryParams)
            setProducts(data)
            console.log(data)
        }catch{

        }
        console.log("Query Params:", queryParams);
        queryParams=""
        setOpen(false)
    };

    return (
        <section>
            <div className=' bg-white rounded-lg xs:hidden sm:block'>
                <div className='p-4 text-lg font-semibold border-b-[1px] boredr-'>
                    <h2 >{t("categories")}</h2>
                </div>
                <ul className='p-4 flex flex-col gap-2 text-gray-600'>
                    <li>
                        <button onClick={handleAllProductsClick}>
                            All Product
                        </button>
                    </li>
                    {Categories.map((item, i) => (
                        <li key={i}>
                            <input
                                type='button'
                                value={item.name}
                                className=' cursor-pointer'
                                onClick={() => handleCategoryClick(item.name)} />
                        </li>
                    ))}
                </ul>
            </div>
            {/* Category and filter in Mobile */}
            <div className='xs:flex sm:hidden flex-between'>
                <div>
                    <select
                        className='p-2 focus:outline-none rounded-lg flex flex-col gap-2 text-gray-600'
                        onChange={(e) => handleCategoryClick(e.target.value)}
                        value={selectedCategory || ''}
                    >
                        <option value="" onClick={handleAllProductsClick}>
                            {t("All Product")}
                        </option>
                        {Categories.map((item, i) => (
                            <option key={i} value={item.name}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className='flex-center gap-2 font-semibold bg-darkColor rounded-lg text-white px-4 py-2 text-lg'>
                    {t("filter")} <CiCircleList />
                </button>
            </div>
            {/*------------------------------------- */}
            <div>
                <MobileFilter
                    open={open}
                    setOpen={setOpen}
                    colors={colors}
                    brands={brands}
                    handleBrandClick={handleBrandClick}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    handleSubmitBrand={handleSubmitBrand}
                />

            </div>
            <div className=' xs:hidden sm:block'>
                <form onSubmit={handleSubmitBrand}>
                    <div className='py-3'>
                        <SectionHead title={t("filters")} />
                    </div>
                    <div className='border-b-[1px] border-[#bdbdbd]'>
                        <div className='px-4 pt-4 text-lg font-semibold'>
                            <h2>{t("brand")}</h2>
                        </div>
                        <div className='p-4 flex flex-col gap-2'>
                            {brands?.map((item, i) => (
                                <label key={i} className='flex items-center gap-2'>
                                    <input
                                        type="checkbox"
                                        value={item}
                                        className='h-4 w-4 rounded'
                                        onChange={() => handleBrandClick(item)} // Ensure `handleBrandClick` updates `selectedBrands`
                                    />
                                    {item}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className='border-b-2 border-[#bdbdbd] py-2'>
                        <div className='px-4 pt-4 pb-2 text-lg font-semibold'>
                            <h2>{t("color")}</h2>
                        </div>
                        <div className='flex px-4 py-2 gap-2'>
                            {colors.map((color, index) => (
                                <label key={index} className='w-6 h-6 transition-all rounded-full cursor-pointer'>
                                    <input
                                        type='radio'
                                        name='color'
                                        value={color.color}
                                        checked={selectedColor === color.color}
                                        onChange={() => setSelectedColor(color.name)}
                                        className='hidden'
                                    />
                                    <span
                                        className={`w-6 h-6 block rounded-full border-[1px] ${selectedColor === color.color ? 'border-darkColor' : 'border-gray-300'}`}
                                        style={{ backgroundColor: color.color }}
                                    />
                                </label>
                            ))}
                        </div>
                    </div>
                    <input
                        type='submit'
                        value={t("apply")}
                        className='hover:scale-105 transition-all cursor-pointer bg-primary text-white px-4 py-2 text-lg my-5'
                    />
                </form>
            </div>
        </section>
    )
}

export default ShopSidebar