import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Product from '../Home/Product';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import Empty from '../ui/Empty';
import { FaSortAmountUpAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { fetchProduct } from '../../utils/data';
import Loading from '../ui/Loading';

const ShopContent = () => {
    const { t } = useTranslation();
    const { products } = useStateContext() as GlobalStateContext;
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [loading, setLoading] = useState(true);
    const { query } = useParams();
    useEffect(() => {
        const fetchSearchResult = async () => {
            if (query) {
                const result = await fetchProduct(query);
                setFilteredProducts(result);
            } else {
                setFilteredProducts(products);
            }
            setLoading(false)
        };

        fetchSearchResult();
    }, [query, products]);
    if(loading)return <Loading/>
    return (
        <>
            {products?.length === 0||filteredProducts?.length === 0 ? <Empty /> :
                <div>
                    <div className='bg-white p-3 rounded-md  flex-between'>
                        <p className='text-xl font-semibold'>{t("products")} </p>
                        <p className='flex-center gap-2 p-2 bg-secondry rounded-md'>
                            <span className='font-bold'>Sort By :</span>
                            <FaSortAmountUpAlt />
                        </p>
                    </div>
                    <ul className='grid py-10 gap-12  md:grid-cols-2 lg:grid-cols-3'>
                        {filteredProducts?.map((product: any, index: number) => (
                            <li key={index}>
                                <Product product={product} />
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    )
}

export default ShopContent