import React from 'react'
import { useTranslation } from 'react-i18next'

const Empty = () => {
    const {t}=useTranslation();
  return (
    <div className=' h-screen w-full flex-center flex-col gap-3'>
        <div className=' h-72'>
            <img className='h-full' src="/asset/empty.png" alt="empty" loading='lazy' />
        </div>
        <p className=' text-center text-lg font-semibold text-primary'>{t("empty")}</p>
    </div>
  )
}

export default Empty