import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { StarRatingProps } from '../../types';

const StarRating: React.FC<StarRatingProps> = ({ rating }:{ rating :number}) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const emptyStars = Math.max(0, 5 - fullStars - (hasHalfStar ? 1 : 0));

    return (
        <div className="flex">
            
            {Array.from({ length: fullStars }, (_, i) => (
                <FaStar key={`full-${i}`} className="text-primary" />
            ))}
            {hasHalfStar && <FaStarHalfAlt className="text-primary" />}
            {Array.from({ length: emptyStars }, (_, i) => (
                <FaRegStar key={`empty-${i}`} className="text-primary" />
            ))}
        </div>
    );
};

export default StarRating;
