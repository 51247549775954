import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { LoginData } from '../../types';
import Input from '../ui/Input';
import { handleAuth } from '../../utils/data';
const LoginForm = () => {
    const { t } = useTranslation();
    const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
    const navigate = useNavigate();
    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleAuth('/login', loginData, 'Login successful', navigate);
    };
    return (
        <div className='   xs:w-4/5 md:w-2/5 flex flex-col justify-center main-padding p-container'>
            <p className='sm:text-2xl xs:text-xl text-black'>{t("hello-again")}</p>
            <p className='sm:text-3xl xs:text-2xl font-bold text-black'>{t("welcome-back")}</p>
            <form onSubmit={handleLogin}>
                <div className=' flex flex-col  gap-2 py-8'>
                    <Input required title={t("email")} type="email" property={loginData.email} changedProp={"email"} data={loginData} setData={setLoginData} />
                    <Input required title={t("password")} type="password" property={loginData.password} changedProp={"password"} data={loginData} setData={setLoginData} />
                </div>
                                <input type="submit"
                    value={t("login")}
                    className=" w-full cursor-pointer  mb-3 flex-center bg-primary border-[1px] rounded-3xl border-none gap-1 py-3 text-white"
                />
                <Link to="/forget-passward" className=" block  p-3 text-[#909090]">{t("forgot-password")}</Link>
                <Link to="/signUp" className="mb-3 p-3 text-primary">{t("don't-have-account")}</Link>
            </form>
        </div>
    )
}

export default LoginForm
