import { t } from 'i18next'
import React, { useState } from 'react'
import { ContactUsData } from '../../types'
import Input from '../ui/Input'
import { IoIosSend } from 'react-icons/io'
import { sendMsg } from '../../utils/data'

const ContactUsForm = () => {
    const [contactUsData, setContactUsData] = useState<ContactUsData>({ name: '', email: '', phoneNumber: '', address: '', message: '' });
    const handleSubmit=(e:React.FormEvent)=>{
        e.preventDefault()
        sendMsg(contactUsData)
    }
    return (
        <div className='p-container'>
            <form onSubmit={handleSubmit}>
                <div className=' grid xs:grid-cols-1 sm:grid-cols-2 gap-5 mb-5'>
                    <Input required title={t("name")} type="text" property={contactUsData.name} changedProp={"name"} data={contactUsData} setData={setContactUsData} />
                    <Input required title={t("email")} type="email" property={contactUsData.email} changedProp={"email"} data={contactUsData} setData={setContactUsData} />
                    <Input title={t("phone-number")} type="number" property={contactUsData.phoneNumber} changedProp={"phoneNumber"} data={contactUsData} setData={setContactUsData} />
                    <Input title={t("address")} type="text" property={contactUsData.address} changedProp={"address"} data={contactUsData} setData={setContactUsData} />
                </div>
                <div className="flex flex-col gap-2 text-lg font-semibold text-[#191919]">
                    <label>{t("message")}</label>
                    <textarea
                        name="message"
                        value={contactUsData.message}
                        onChange={(e) => setContactUsData({ ...contactUsData, message: e.target.value })}
                        required
                        className='text-black h-44 max-h-44 p-3 rounded-xl bg-white focus:outline-none'
                    />
                </div>
                <button
                    type="submit"
                    className="w-fit cursor-pointer my-3 flex-center bg-primary rounded-3xl px-4 border-none gap-1 py-3 text-white"
                >
                    {t("send")} <IoIosSend />
                </button>
            </form></div>
    )
}

export default ContactUsForm