
const Map = () => {
    return (
        <div data-aos="fade-up" className='h-[600px] rounded-5xl overflow-hidden'>
            <a
                href="https://goo.gl/maps/MjyZTKQBvbTH1DcD6"
                target="_blank" rel="noreferrer" 
                className="w-full h-full "
            >
                <img
                    alt="تواصل معنا" 
                    className="w-full h-full rounded-5xl overflow-hidden object-cover image-link"
                    src="/asset/map.jpg"
                    loading="lazy"
                />
            </a>
        </div>
    );
};

export default Map;
