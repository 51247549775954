import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import SectionHead from '../ui/SectionHead';
import Slider from '../ui/Slider';
import Product from './Product';
import { fetchNewOffers } from '../../utils/data';
import { ProductType } from '../../types';
import Loading from '../ui/Loading';

const NewOffers = () => {
    const { t, } = useTranslation();
	const { data: newDeals, loading, error } = useFetch(fetchNewOffers);
	if (loading) return <Loading/>;
	if (error) return <div>Error fetching banners: {error.message}</div>;
	return (
		<div className=' p-container main-padding'>
			<SectionHead title={t("new-offers")} action={t("shop-now")} />
			<Slider
				items={newDeals}
				renderSlide={(item:ProductType) => <Product product={item} />}
			/>
		</div>
	)
}

export default NewOffers
