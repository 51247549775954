import React from 'react'
import StarRating from '../../components/ui/StarRating'
import { useTranslation } from 'react-i18next'
import { FaPhone } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';
import { User } from '../../types';

const SellerSidebar = ({ user }: { user: User }) => {
    const { t } = useTranslation();
    return (
        <div className='w-full   bg-white p-5 rounded-lg '>
            <div className='flex-center flex-col gap-2 p-2 border-b-[1px] border-secondry'>
                <div className='w-20 h-20 border-[1px] overflow-hidden rounded-full '>
                    <img src={user?.image} alt="user-image" />
                </div>
                <p className=' text-xl font-bold text-darkColor'>{user.name}</p>
                <StarRating rating={user.rating} />
                <p className='py-1'>{user?.location}</p>
            </div>
            <div>
                <p className='  text-gray-500 py-2'>
                    "{user?.storeDescription}"
                </p>
                <div className='py-3'>
                    <p className='text-lg font-semibold text-primary'>{t("contact")}</p>
                    <div className='px-3'>
                        <p className='pb-1 flex items-center gap-1'>
                            <span className=' text-darkColor font-semibold pe-1'>
                                <FaPhone />
                            </span>
                            {user.phoneNumber}
                        </p>
                        <p className='pb-1 flex items-center gap-1'>
                            <span className=' text-darkColor font-semibold pe-1'>
                                <MdAlternateEmail />
                            </span>
                            {user.email}
                        </p>
                    </div>
                </div>
                <div className='py-3'>
                    <p className='text-lg font-semibold text-primary'>{t("socialMedia")}</p>
                    <ul className='pt-2 px-3 w-full overflow-hidden'>
                        {
                            Object.entries(user.socialMediaLinks || {}).map(([key, value], index) => (
                                <li key={index} className='pb-1'>
                                    <span className='text-darkColor font-semibold pe-1'>{key}:</span>
                                    {value ? String(value) : "not exist"}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default SellerSidebar