import React from 'react'
import Button from './Button'
import { Banner } from '../../types'

const MainBanner = ({banner}:{banner:Banner}) => {
  return (
    <div className=' flex justify-center relative xs:text-center sm:text-start xs:flex-col min-h-full p-10'>
        <div>
            <p className='text-xl'>enjoy with</p>
            <p className=' text-5xl font-extrabold '>Summer Sale</p>
            <h1 className=' xs:text-6xl lg:text-8xl xs:text-wrap sm:text-nowrap pt-5 font-extrabold text-white'>{banner.title}</h1>
            <p className='text-lg text-darkColor '>{banner.description}</p>
            <div className=' flex items-center sm:justify-start xs:justify-center gap-10 text-xl font-bold  py-8'>
                <p> Sale Off <span className=' text-darkColor'>{banner.discount}</span></p>
                <p className=' text-darkColor'>{banner.price}</p>
            </div>
            <div className=' xs:flex-center sm:block w-full'>
                <Button value={"Shop Now"} link={"#"} color={"primary"} />
            </div>
        </div>
        <div className='  sm:absolute sm:top-0  sm:-right-64  lg:right-0  xs:relative '>
            <div className=' xs:w-full md:w-3/5 lg:w-full transition-all duration-300 hover:scale-105 '>
                <img src={banner.image} alt="banner1" loading='lazy' />
            </div>
        </div>
    </div>
  )
}

export default MainBanner