import React from 'react'
import useFetch from '../../custom hooks/UseFetch'
import { fetchAd } from '../../utils/data'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Ad = () => {
    const {data:ad}=useFetch(fetchAd);
    const {t}=useTranslation();
  return (
    <div className=' p-container main-padding'>
        <div className=' bg-[#191919]  overflow-hidden flex-between xs:flex-col md:flex-row p-container main-padding text-white rounded-5xl'>
            <div>
                <h2 className='text-xl font-semibold pb-6 text-primary' >{t("today-deal")}</h2>
                <h3 className=' text-4xl font-extrabold'>{ad?.title}</h3>
                <p className='text-xl leading-8 tracking-wider md:w-4/5 xs:w-full py-5'>{ad?.description}</p>
                <p className=' text-primary text-sm'> <span className='text-4xl'>{ad?.discount} </span>{t("discount")}</p>
                <div className='py-10 relative z-20'>
                <Link to={"/"} className=' text-lg px-8 py-3 bg-primary'>{t("shop-now")}</Link>    
                </div>
                
            </div>
            <div className=' relative flex-center xs:w-full md:w-fit '>
                <div className='ad-bg-shadow top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'></div>
                <div className='w-full'>
                    <img src={ad?.image} alt="ad-image"  loading='lazy' className=' w-full relative z-20'/>     
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Ad