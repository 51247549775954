import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchElctronic } from '../../utils/data';
import Slider from '../ui/Slider';
import Product from './Product';
import SectionHead from '../ui/SectionHead';
import {  ProductType } from '../../types';
const ElectronicOffers = () => {
const { t } = useTranslation();
	const { data: deals } = useFetch(fetchElctronic);
	return (
		<div className=' p-container main-padding'>
			<SectionHead title={t("electronic-offers")} action={t("shop-now")} />
			<Slider
				items={deals}
				renderSlide={(item:ProductType) => <Product product={item} />}
			/>
		</div>
	)
}

export default ElectronicOffers