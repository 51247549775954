import React from 'react'
import { LinkProps } from '../../types'
import { Link } from 'react-router-dom'

const Button = ({value,link,icon,color}:LinkProps) => {
  return (
    <div>
        <Link to={link} className={`w-fit text-lg flex-center gap-2 bg-${color} text-white px-2 py-1 rounded-md`}>
        <p>{value} </p>
        <span>{icon}</span>
        </Link>
    </div>
  )
}

export default Button