import React from 'react'
interface InputProps{
    title:string;
    property:string;
    data:any;
    setData:any
    required?:boolean;
    changedProp:any;
    type:string
}
const Input = ({ title, property, data, setData, required,changedProp,type }: InputProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [changedProp]: e.target.value });
    };

    return (
        <div className="flex-col flex gap-2">
            <label className='text-lg font-semibold text-[#191919]'>
                {title}
                {required && <span className='text-primary'>*</span>}
            </label>
            <input
                type={type}
                value={property}
                onChange={handleChange}
                required={required}
                className='text-black  p-3 rounded-3xl bg-white focus:outline-none'
            />
        </div>
    );
};

export default Input