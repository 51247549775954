
import { IoMdClose } from 'react-icons/io'
import { useTranslation } from 'react-i18next';
import SectionHead from '../ui/SectionHead';
import React from 'react';
interface MobileFilterProps{
    open:boolean;
    setOpen:React.Dispatch<React.SetStateAction<boolean>>
    colors:{name:string;color:string;}[];
    brands:string[];
    handleBrandClick: (brand: string) => void;
    selectedColor: string;
    setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
    handleSubmitBrand:any;
}
const MobileFilter = ({ open, setOpen, colors, brands, handleBrandClick, selectedColor, setSelectedColor, handleSubmitBrand }: MobileFilterProps) => {
    const { t } = useTranslation();
    return (
        <div className={`fixed bg-[#00000090] z-50 md:hidden xs:flex  h-full top-0 left-0 ${open ? "w-full" : "w-0"}`}>
            <div className={`fixed bg-secondry  z-50 transition-all duration-300  h-full top-0 left-0 py-8 ${open ? "w-4/6 overflow-visible" : "w-0 overflow-hidden"} `}>
                <button type='button' onClick={() => setOpen(false)} title='close menu' className={` ${open ? "flex" : "hidden"} text-white absolute -right-12 z-50 border-[1px] border-secondry p-1 text-xl`}>
                    <IoMdClose />
                </button>
                <div className='flex justify-between px-4 flex-col h-full'>
                    <form onSubmit={handleSubmitBrand}>
                        <div className=' py-3'>
                            <SectionHead title={t("filters")} />
                        </div>

                        <div className='border-b-[1px] border-[#bdbdbd]'>
                            <div className='px-4 pt-4  text-lg font-semibold '>
                                <h2 >{t("brand")}</h2>
                            </div>
                            <div className='p-4 flex flex-col gap-2'>
                                {brands?.map((item: string, i: number) => (
                                    <label key={i} className='flex items-center gap-2'>
                                        <input type="checkbox" value={item} className=' h-4 w-4  rounded ' onChange={() => handleBrandClick(item)} />
                                        {item}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className='border-b-2 border-[#bdbdbd] py-2'>
                            <div className='px-4 pt-4 pb-2 text-lg font-semibold '>
                                <h2 >{t("color")}</h2>
                            </div>
                            <div className='flex px-4 py-2 gap-2'>
                                {colors.map((color: any, index: number) => (
                                    <button
                                        key={index}
                                        className={`w-6 h-6 transition-all rounded-full border-[1px] ${selectedColor === color.color ? 'border-darkColor' : 'border-gray-300'
                                            }`}
                                        style={{ backgroundColor: color.color }}
                                        onClick={() => setSelectedColor(color.color)}
                                    >
                                    </button>
                                ))}
                            </div>
                        </div>
                        <input
                            type='submit'
                            value={t("apply")}
                            className='hover:scale-105 transition-all cursor-pointer bg-primary text-white px-4 py-2 text-lg my-5'
                        />                    
                        </form>
                </div>

            </div>
        </div>
    )
}

export default MobileFilter