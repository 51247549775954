import { useState } from 'react'
import Logo from '../ui/Logo'
import { FaBars, FaRegUserCircle } from 'react-icons/fa';
import { RiShoppingBagLine } from "react-icons/ri";
import Navbar from './Navbar';
import MobileNavbar from './MobileNavbar';
import { Link } from 'react-router-dom';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import SearchForm from '../forms/SearchForm';
const Header = () => {
    const [open, setOpen] = useState(false);
    const { user, totalQuantities } = useStateContext() as GlobalStateContext;
    return (
        <header className='  w-full'>
            <div className='p-container py-4 border-b-[1px] border-b-secondry  w-full'>

                <div className='flex-between w-full  '>
                    <Logo />
                    <div className=' xs:hidden sm:block w-1/3'>
                        <SearchForm />
                    </div>
                    <div className=' flex gap-4 items-center sm:text-2xl xs:text-xl'>
                        {
                            user &&
                            <Link to={`/profile/${user.id}`} className='text-2xl text-secondry'>
                                <FaRegUserCircle />
                            </Link>
                        }
                        <Link to={"/cart"} className=' relative '>
                            <span className=' absolute rounded-full flex-center text-white w-4 h-4 bg-primary -top-1 -right-2 text-sm'>{totalQuantities}</span>
                            <p className='text-secondry'><RiShoppingBagLine /></p>
                        </Link>
                        <div className=' xs:flex-center  p-1 sm:hidden border-[1px] '>
                            <button type='button'
                                onClick={() => setOpen(true)}
                            >
                                <FaBars />
                            </button>
                        </div>
                    </div>
                </div>
                <div className=' sm:hidden xs:block pt-2 '>
                    <SearchForm />
                </div>
            </div>
            <Navbar />
            <MobileNavbar open={open} setOpen={setOpen} />
        </header>
    )
}

export default Header