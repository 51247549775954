import React, { useMemo } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../../types';

const Slider = ({ items, renderSlide }: { items: ProductType[], renderSlide: (item: ProductType) => React.ReactNode }) => {
    const { i18n } = useTranslation();
    const uniqueId = useMemo(() => `swiper-${Math.random().toString(36).substring(2, 9)}`, []);
    return (
        <div className='py-7 relative'>
            <Swiper
                spaceBetween={20}
                slidesPerView={3}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    1300: {
                        slidesPerView: 5,
                    },

                }}
                navigation={{
                    nextEl: `.${uniqueId}-next`,
                    prevEl: `.${uniqueId}-prev`,
                }}
                pagination={{ clickable: true }}
                modules={[Navigation]}
                dir={i18n.language==="ar"?'rtl':"ltr"}
            >
                {items?.map((item, index) => (
                    <SwiperSlide key={index}>
                        {renderSlide(item)}
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={`absolute w-24 -top-6  ${i18n.language === 'ar' ?"left-5":"right-5"}`}>
            <div className={`${uniqueId}-next custom-swiper-button swiper-button-next`}></div>
            <div className={`${uniqueId}-prev custom-swiper-button swiper-button-prev`}></div>
            </div>
        </div>
    );
}

export default Slider;
