import { GlobalStateContext, useStateContext } from '../../context/StateContext'
import Empty from '../../components/ui/Empty';
import CartItem from '../../components/cart/CartItem';
import { useTranslation } from 'react-i18next';
import CartSidebar from '../../components/cart/CartSidebar';
import { ProductType } from '../../types';

const Cart = () => {
    const { t } = useTranslation();
    const { cartItems,setCartItems,setTotalPrice,setTotalQuantities } = useStateContext() as GlobalStateContext;
    if (cartItems.length === 0) {
        const storedCartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
        const storedTotalPrice = JSON.parse(localStorage.getItem("totalPrice") || "0");
        const storedTotalQuantities = JSON.parse(localStorage.getItem("totalQuantities") || "0");
        if(storedCartItems.length>0){
            setCartItems(storedCartItems);
            setTotalPrice(storedTotalPrice);
            setTotalQuantities(storedTotalQuantities)
        }
    }
    if (cartItems.length === 0) return <Empty />
    console.log(cartItems)
    return (
        <div className=' bg-secondry p-container main-padding'>
            <div className=' flex xl:flex-row xs:flex-col  gap-8'>
                <div className='bg-white rounded-3xl h-fit xs:w-full xl:w-2/3 p-container main-padding'>
                    <div className=' md:grid xs:hidden md:grid-cols-4 text-lg font-semibold pb-3 border-b-[1px] border-secondry '>
                        <p>{t("product-detail")}</p>
                        <p>{t("price")}</p>
                        <p>{t("quantity")}</p>
                        <p>{t("remove")}</p>
                    </div>
                    {cartItems.map((product: ProductType, index: number) => (
                        <div key={index}>
                            <CartItem product={product} />
                        </div>
                    ))}
                </div>
                <div className='bg-white rounded-3xl p-container h-fit main-padding  xs:w-full xl:w-1/3'>
                    <CartSidebar/>
                </div>
            </div>
        </div>
    )
}

export default Cart