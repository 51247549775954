import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Header from "../components/Home/Header";
import Footer from "../components/Home/Footer";
import ScrollToTop from "../components/ui/ScrollToTop";
function App() {
    const {i18n} = useTranslation();
    return (
        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <ScrollToTop />
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
}
export default App;
