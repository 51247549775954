import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { FaCheck, FaRegCheckCircle } from 'react-icons/fa';
import StarRating from '../ui/StarRating';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import { ProductType } from '../../types';

const ProductDetailsCont = ({ product }: { product: ProductType }) => {
    const navigate = useNavigate()
    const { onAdd, incQty, decQty, qty } = useStateContext() as GlobalStateContext;
    const { t } = useTranslation();
    const handleBuynow = () => {
        onAdd(product, qty)
        navigate("/cart")
    }
    return (
        <div>
            <h2 className=' text-xl font-bold pb-3'>{product?.name}</h2>
            <div className='pt-1'><StarRating rating={product.rating} /></div>
            <div className='flex-between pt-3 w-fit gap-x-10'>
                {product.discount?.discounted_price && (
                    <p className=' text-primary text-lg font-bold'>
                        {product.discount.discounted_price} $
                    </p>
                )}
                <p className={`${product.discount ? "text-gray-400 line-through text-base font-normal" : "text-primary text-lg font-bold"}`}>
                    {product.price} $
                </p>
            </div>
            <div className=' py-3 text-lg font-semibold text-darkColor'>
                <p>{t("availability")}: <span className=' text-primary font-bold'>{product.availability}</span></p>
            </div>
            <div>
                <p className='text-darkColor text-lg font-semibold'>{t("accessories")}:</p>
                <ul className='grid grid-cols-2 w-fit gap-10 pt-1 pb-3'>
                    {product.accessories.map((item: string, index: number) => (
                        <li key={index} className='flex items-center gap-1 text-primary'>
                            <FaCheck />
                            <span className='text-black'>{item}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <p className='text-darkColor text-lg font-semibold'>{t("details")}</p>
            <p className='text-gray-500'>{product?.description}</p>
            <div className='pt-2 pb-4'>
                {product.compatibility.map((item: string, index: number) => (
                    <div key={index} className='flex items-center gap-1'>
                        <span className='text-primary'><FaRegCheckCircle /></span>
                        <span>{item}</span>
                    </div>
                ))}
            </div>

            {product.available_colors && (
                <div className='pb-3'>
                    <p className='text-darkColor text-lg font-semibold'>{t("color")}</p>
                    <div className='flex w-fit gap-10'>
                        {product.available_colors.map((item: string, index: number) => (
                            <div key={index} className='flex gap-1'>
                                <input type="radio" name="color" value={item} id={item} />
                                <label htmlFor={item}>{item}</label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <Link to={`/sellerProfile/${product.seller.id}`} className='flex items-center gap-3 py-3'>
                <div className='h-14 w-14 rounded-full overflow-hidden'>
                    <img src={product.seller.image} alt="seller-pic" loading='lazy' className='w-full h-full' />
                </div>
                <div>
                    <p className='text-lg font-bold text-darkColor'>{product.seller.sellerName}</p>
                    <p className='text-gray-500'>{product.seller.sellerLocation}</p>
                </div>
            </Link>
            <p className='text-2xl font-extrabold text-[#191919]'>{t("hurry-1")} {product.stock_quantity} {t("hurry-2")}</p>
            <div className="flex items-center gap-3 py-3">
                <h3 className='text-darkColor text-lg font-semibold'>{t("quantity")}:</h3>
                <div className='flex items-center gap-2 border-[1px] border-darkColor'>
                    <span
                        onClick={decQty}
                        className="p-2 cursor-pointer border-e-[1px] text-red-500 border-darkColor" ><AiOutlineMinus /></span>
                    <span className="num">{qty}</span>
                    <span
                        onClick={incQty}
                        className="p-2 cursor-pointer border-s-[1px] text-green-500 border-darkColor"><AiOutlinePlus /></span>
                </div>
            </div>
            <div className="flex gap-5 pt-3">
                <button
                    onClick={() => onAdd(product, qty)}
                    type="button"
                    className="hover:scale-105 transition-all text-lg text-primary border-primary py-3 px-6 font-semibold border-[1px]">
                    {t("add-to-cart")}
                </button>
                <button
                    type="button"
                    onClick={handleBuynow}
                    className="hover:scale-105 transition-all text-lg text-white bg-primary py-3 px-6 font-semibold">
                    {t("buy-now")}
                </button>
            </div>
        </div>
    );
};

export default ProductDetailsCont;
