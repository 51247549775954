
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const Logo = () => {
    const {t}=useTranslation();
  return (
    <Link to={"/"} className='text-darkColor font-bold text-lg'>
        {t("logo")}.
    </Link>
  )
}

export default Logo