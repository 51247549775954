import React from 'react'
import { useTranslation } from 'react-i18next';
import { Circles } from "react-loader-spinner";
const Loading = () => {
    const { t } = useTranslation();
    return (
        <div className=' flex-center h-screen w-full flex-col gap-3'>
            <Circles
                height="80"
                width="80"
                color="#f02d34"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <p>{t("loading")}</p>
        </div>
    )
}

export default Loading