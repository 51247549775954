import React from 'react'
import Button from './Button'
import { Banner } from '../../types'

const SmallBanner = ({ banner }: { banner:Banner }) => {
    return (
        <div className=' xs:hidden lg:flex flex-between flex-col p-10'>
            <div>
                <div className=' w-fit'>
                    <Button value={"Shop Now"} link={"#"} color={"primary"} />
                </div>
                <h1 className='text-4xl pt-5 font-extrabold text-white'>{banner.title}</h1>
                <p className='text-lg text-secondry'>{banner.description}</p>
                <div className=' flex items-center gap-10 text-xl font-bold  py-8'>
                    <p> Sale Off <span className=' text-secondry'>{banner.discount}</span></p>
                    <p className=' text-primary'>{banner.price}</p>
                </div>

            </div>
            <div>
                <div className=' '>
                    <img src={banner.image} alt="banner1" loading='lazy' />
                </div>
            </div>
        </div>
    )
}

export default SmallBanner