
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { navbarlinks, navbarlinks_en } from '../../constant';
import { IoMdLogIn, IoMdPersonAdd } from "react-icons/io";
import { useStateContext, GlobalStateContext } from '../../context/StateContext';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const { user } = useStateContext() as GlobalStateContext;
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <nav className=' relative xs:hidden sm:flex-between bg-darkColor p-container  text-white' >
            <ul className=' h-full flex gap-7  font-semibold text-lg text-nowrap'>
                {links.map((link, index) => (
                    <li key={index} className='transition-all overflow-hidden py-3 h-full hover:bg-primary'>
                        <NavLink
                            to={`/${link.href}`}
                            className={({ isActive }) =>  `p-3  h-full ${isActive &&" bg-primary"}`}
                        >
                            {link.name}
                        </NavLink>
                    </li>
                ))}
            </ul>

            {
                !user &&
                <div className='flex gap-5'>
                    <Link className='flex gap-1 text-lg items-center font-semibold transition-all hover:text-primary' to={"logIn"}>{t("login")}<IoMdLogIn /></Link>
                    <Link className='flex gap-1 text-lg items-center font-semibold transition-all hover:text-primary' to={"signup"}>{t("signUp")}<IoMdPersonAdd /></Link>
                </div>
            }
        </nav>
    )
}
export default Navbar
