import React from 'react'
import { useTranslation } from 'react-i18next'
import { categories, categories_en, navbarlinks, navbarlinks_en, socialMediaLinksMMtech } from '../../constant';
import { Link } from 'react-router-dom';
import LanguageSwitcher from '../ui/LanguageSwitcher';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const Category = i18n.language === "ar" ? categories : categories_en;
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <footer className=' overflow-hidden relative bg-[#191919] text-white'>
            <div className='p-container main-padding  relative z-20'>
                <div>
                    <div className='flex xs:flex-col md:flex-row gap-20'>
                        <div className=' xs:w-full sm:w-1/2 md:w-1/3'>
                            <div>
                                <p className=' text-xl font-bold pb-2'>{t("logo")} .</p>
                                <p className='text-secondry'>{t("website-desc")}</p>
                            </div>
                            <div>
                                <p className='font-bold pt-5 pb-3'>{t("contact-us")}</p>
                                <ul className='flex items-center gap-4 text-xl '>
                                    {socialMediaLinksMMtech.map((item, i) => (
                                        <li key={i}>
                                            <Link to={item.href} className='text-secondry transition-all duration-300 hover:text-primary'>
                                                <p>{item.icon}</p>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className=' pt-6'>
                            <LanguageSwitcher/>    
                            </div>
                            
                        </div>
                        <div className='grid grid-cols-2 gap-10'>
                            <div>
                                <p className=' text-lg font-bold pb-5 '>{t("popular-category")}</p>
                                <ul className='text-secondry flex flex-col gap-2 '>
                                    {Category.slice(0, 8).map((item, i) => (
                                        <li key={i}>
                                            <p>{item.name}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <p className=' text-lg font-bold pb-5 '>{t("customer-service")}</p>
                                <ul className='text-secondry flex flex-col gap-2 '>
                                    {links.map((item, i) => (
                                        <li key={i}>
                                            <Link to={item.href}>
                                                <p>{item.name}</p>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='border-t-[1px] border-t-secondry pt-5 mt-10'>
                        <div className=' flex-center flex-col  gap-5 '>
                            <div className='text-nowrap'>{t("copy-rights")} &copy;</div>
                            <ul className='flex gap-x-5 text-2xl'>
                                {
                                    socialMediaLinksMMtech.map((item, index) => (
                                        <li key={index}>
                                            <Link to={item.href}>{item.icon}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` ${i18n.language === "ar" ? "-left-32" : "-right-32"} absolute -top-44 border-8 border-[#191919] outline-2 outline-black outline  bg-black w-[450px] h-[450px] rounded-full`}>
            </div>
        </footer>
    )
}

export default Footer