import { FaFacebook, FaWhatsapp, FaGlobe, FaLinkedin } from 'react-icons/fa';
import { MdModeOfTravel } from "react-icons/md";
import { FaTshirt, FaMobileAlt, FaLaptop, FaCouch, FaBook, FaBlender, FaBasketballBall, FaGuitar, FaCarAlt, FaBabyCarriage } from 'react-icons/fa';
import { GiConverseShoe, GiLipstick, GiRunningShoe, GiTennisRacket, GiWashingMachine, GiLargeDress } from 'react-icons/gi';

export const socialMediaLinksMMtech = [
    { name: "Facebook", href: "https://www.facebook.com/mmtechly", icon: <FaFacebook /> },
    { name: "WhatsApp", href: "https://wa.me/218911919187", icon: <FaWhatsapp /> },
    { name: "Website", href: "http://mmtech.ly/", icon: <FaGlobe /> },
    { name: "LinkedIn", href: "https://www.linkedin.com/company/mmtechly?trk=organization_guest_main-feed-card_reshare-text&originalSubdomain=ly", icon: <FaLinkedin /> }
];
export const navbarlinks = [
    { name: "الرئيسية", href: "#hero" },
    { name: "افضل العروض", href: "best-deals" },
    { name: "المتجر", href: "shop" },
    { name: "تواصل معنا", href: "contact-us" },
]
export const navbarlinks_en = [
    { name: "Home", href: "#hero" },
    { name: "Best Deals", href: "best-deals" },
    { name: "shop", href: "shop" },
    { name: "Contact us", href: "contact-us" },
]

export const categories_en = [
    { name: 'Clothing', img:" /asset/clothing.webp" },
    { name: 'Footwear', img:" /asset/footwear.webp" },
    { name: 'Accessories', img:" /asset/accessories.webp" },
    { name: 'Beauty & Health', img:" /asset/beauty-health.webp" },
    { name: 'Electronics', img:" /asset/electronics.webp" },
    { name: 'Laptops', img:" /asset/laptops.webp" },
    { name: 'Home & Furniture', img:" /asset/home.webp" },
    { name: 'Books', img:" /asset/books.webp" },
    { name: 'Appliances', img:" /asset/appliances.webp" },
    { name: 'Sports', img:" /asset/sports.webp"},
    { name: 'Musical Instruments', img:" /asset/musical.webp" },
    { name: 'Automotive', img:" /asset/automotive.webp" },
    { name: 'Baby & Kids', img:" /asset/baby.webp" },
    { name: 'Fashion', img:" /asset/fashion.webp" },
    { name: 'Outdoor & Travel', img:" /asset/travel.webp"}
];

export const categories = [
    { name: 'الملابس', icon: <FaTshirt /> },
    { name: 'الأحذية', icon: <GiRunningShoe /> },
    { name: 'الإكسسوارات', icon: <GiConverseShoe /> },
    { name: 'الجمال والصحة', icon: <GiLipstick /> },
    { name: 'الإلكترونيات', icon: <FaMobileAlt /> },
    { name: 'الحواسيب المحمولة', icon: <FaLaptop /> },
    { name: 'المنزل والأثاث', icon: <FaCouch /> },
    { name: 'الكتب', icon: <FaBook /> },
    { name: 'الأجهزة المنزلية', icon: <GiWashingMachine /> },
    { name: 'الرياضة', icon: <FaBasketballBall /> },
    { name: 'الآلات الموسيقية', icon: <FaGuitar /> },
    { name: 'السيارات', icon: <FaCarAlt /> },
    { name: 'الرضع والأطفال', icon: <FaBabyCarriage /> },
    { name: 'أجهزة المطبخ', icon: <FaBlender /> },
    { name: 'الموضة', icon: <GiLargeDress /> },
    { name: 'الأنشطة الخارجية والسفر', icon: <MdModeOfTravel /> },
    { name: 'معدات رياضية', icon: <GiTennisRacket /> },
];
export const colors = [
    { name: 'Black', color: '#000000' },
    { name: 'White', color: '#FFFFFF' },
    { name: 'Blue', color: '#0000FF' },
    { name: 'Red', color: '#FF0000' },
    { name: 'Green', color: '#00FF00' },
];

export const paymentsWay = ["Direct Bank Transfer", "Cash on Delivery", "Card Payment"]
export const searchChoose = [
    { title: "Product", value: "name" },
    { title: "Category", value: "category" },
    { title: "Brand", value: "brand" }
]